import {
  Card,
  Col,
  Row,
  Statistic,
  Space,
  Typography,
  Rate,
  Progress,
} from "antd";
import { LikeOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { getDailyReport } from "./service";
import { Loading } from "./components/loading";

const images = [
  { src: "img1", label: "RẤT TỆ" },
  { src: "img1", label: "CHƯA HÀI LÒNG" },
  { src: "img1", label: "BÌNH THƯỜNG" },
  { src: "img1", label: "HÀI LÒNG" },
  { src: "img1", label: "RẤT HÀI LÒNG" },
];

export const Admin = () => {
  const [dailyReport, setDailyReport] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    getDailyReport()
      .then((res) => setDailyReport(res.data))
      .finally(setIsLoading(false));
  }, []);
  const { Title, Text } = Typography;

  const twoColors = {
    "0%": "#408c7d",
    "100%": "#87d068",
  };

  return !dailyReport ? (
    <Loading />
  ) : (
    <div className="container" style={{ marginLeft: "145px" }}>
      <Row
        justify="center"
        align="middle"
        gutter={[12, 12]}
        style={{ marginTop: "50px" }}
      >
        <Col span={6}>
          <Card
            title={
              <Title level={4} style={{ color: "#408c7d" }}>
                Rate hôm nay
              </Title>
            }
            style={{ marginTop: "80px", width: "250px" }}
            bordered
          >
            <Row gutter={16}>
              <Col span={12}>
                <Statistic
                  title="Lượt tương tác"
                  value={dailyReport?.totalVotesToday}
                  prefix={<LikeOutlined style={{ color: "#408c7d" }} />}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title="Tỉ lệ"
                  value={dailyReport?.totalVotesToday}
                  suffix={` /${dailyReport?.totalVotes || 0}`}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={8}>
          <Card style={{ width: "380px" }}>
            <Space direction="vertical">
              <Text strong>Đánh giá của khách hàng</Text>
              <Space>
                {dailyReport ? (
                  <Rate
                    allowHalf
                    defaultValue={
                      (
                        Object.values(dailyReport?.votes)
                          .map((item, index) => {
                            if (item.total) {
                              return item.total * (index + 1);
                            }
                          })
                          .filter((item) => item)
                          .reduce((partialSum, a) => partialSum + a, 0) /
                        dailyReport.totalVotes
                      )?.toFixed(1) || 0
                    }
                    disabled
                  />
                ) : null}
                <Text strong>
                  {(dailyReport &&
                    (
                      Object.values(dailyReport?.votes)
                        .map((item, index) => {
                          return item.total * (index + 1);
                        })
                        .filter((item) => item)
                        .reduce((partialSum, a) => partialSum + a, 0) /
                      (dailyReport.totalVotes ?? 0)
                    )?.toFixed(1)) ||
                    0}
                  / 5 ({dailyReport?.totalVotes} khách hàng)
                </Text>
              </Space>
              {dailyReport &&
                Object.values(dailyReport?.votes).map((item, index) => (
                  <div key={index}>
                    <Space>
                      <Row style={{ width: "320px" }}>
                        <Col span={8}>
                          <Text>{images[index].label}</Text>
                        </Col>
                        <Col span={16}>
                          <Progress
                            percent={item?.yesterdayPercentage?.toFixed(1) || 0}
                            size={[200, 15]}
                            strokeColor={twoColors}
                          />
                        </Col>
                      </Row>
                    </Space>
                  </div>
                ))}
            </Space>
          </Card>
        </Col>
      </Row>
      <Row
        justify="center"
        align="middle"
        gutter={[12, 12]}
        style={{ marginTop: "12px" }}
      >
        {dailyReport &&
          Object.values(dailyReport?.votes).map((value, index) => (
            <Col key={index} style={{ textAlign: "center" }}>
              <Card
                bordered={false}
                style={{ width: "120px", height: "150px" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "20px",
                  }}
                >
                  {/* <img
                    src={images[index].src}
                    alt={images[index].label}
                    style={{ width: "40px", height: "auto" }}
                  /> */}
                </div>
                <Space size="large" direction="vertical">
                  <div>
                    <Statistic
                      title={images[index].label}
                      value={value.total}
                      valueStyle={{
                        color: "#3f8600",
                        textAlign: "center",
                      }}
                    />
                  </div>
                </Space>
              </Card>
            </Col>
          ))}
      </Row>
    </div>
  );
};
