import "./style.css";
export const Loading = () => {
  return (
    <div class="loading">
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
