import axios from "axios";
const url = process.env.REACT_APP_API_ENDPOINT;

export const saveVoting = (voteId) => {
  return axios.post(`${url}/rate`, { rate: voteId });
};

export const getDailyReport = () => {
  return axios.get(`${url}/daily-report`);
};
