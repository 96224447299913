import img1 from "../imgs/Bad.png";
import img2 from "../imgs/Sad.png";
import img3 from "../imgs/medium.png";
import img4 from "../imgs/Pleased.png";
import img5 from "../imgs/pleased2.png";
import { Space, Row, Col, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import "./vote.css";
import { saveVoting } from "./service";
import { useState } from "react";
import { Loading } from "./components/loading";

const images = [
  { src: img1, label: "RẤT TỆ", width: '105px' },
  { src: img2, label: "CHƯA HÀI LÒNG", width: '125px'},
  { src: img3, label: "BÌNH THƯỜNG", width: '130px' },
  { src: img4, label: "HÀI LÒNG", width: '105px' },
  { src: img5, label: "RẤT HÀI LÒNG", width: '105px' },
];

export const VotePage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleClickVote = (voteId) => {
    setIsLoading(true); // Bắt đầu hiển thị màn hình chờ

    saveVoting(voteId)
      .then((res) => {
        navigate("/thank-you");
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return isLoading ? (
    <Loading />
  ) : (
    <div>
      <div className="container">

        <div style={{marginLeft: '300px'}}>
        <div
          style={{
            textAlign: "center",
            maxWidth: "80%",
            margin: "auto",
          }}
        >
          <p
            style={{
              color: "white",
              fontSize: "30px",
              fontFamily: "Montserrat",
              fontWeight: "bold",
            }}
          >
            Xin chào! Quý khách cảm thấy dịch vụ của chúng tôi hôm nay thế nào?
          </p>
        </div>
          <Row gutter={[16, 16]} justify={"center"}>
            {images.map((image, index) => (
              <Col key={index}>
                <Space align="center" direction="vertical">
                  <div onClick={() => handleClickVote(index + 1)}>
                  <img
                        src={image.src}
                        alt="fireSpot"
                        style={{ width: image.width, height: "auto" }}
                      />
                  </div>
                </Space>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </div>
  );
};
