import WebFont from "webfontloader";
import React, { useEffect } from "react";
import { VotePage } from "./pages/vote.page";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Thank } from "./pages/thank.page";
import { Admin } from "./pages/admin.page";
const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <div>
        <VotePage />
      </div>
    ),
  },
  {
    path: "/thank-you",
    element: <Thank />,
  },

  {
    path: "/admin",
    element:  <Admin />,
  },
]);

function App() {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Montserrat:400,700"],
      },
    });
  }, []);
  return (
    <div>
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    </div>
  );
}

export default App;
