import { useNavigate } from "react-router-dom";
import { Typography } from "antd";
import { useEffect } from "react";
import "./vote.css";
export const Thank = () => {
  const { Title } = Typography;
  const navigate = useNavigate();
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/");
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="container">
      <div style={{marginLeft: '350px'}}>
        <Title
          style={{
            color: "white",
            fontSize: "40px",
            maxWidth: "95%",
            padding: "40px",
            textAlign: "center",
            fontFamily: "Montserrat",
            fontWeight: "bold",
          }}
        >
          Xin cảm ơn sự đánh giá của Quý Khách, BB Beauty sẽ liên tục nâng cao
          chất lượng dịch vụ.
          <br />
          THANK YOU!
        </Title>
      </div>
    </div>
  );
};
